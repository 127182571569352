import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage';
import Home from '../pages/Home';
import ForgotPassword from '../pages/ForgotPassword';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Verify from '../pages/Verify';
import CreateUser from '../pages/CreateUser';
import ProtectedRoute from './ProtectedRoute';
import RedirectToDashboard from './RedirectToDashboard';

const AppRouter = () => (
  <BrowserRouter>
    <div dir="rtl" lang="he">
      <Helmet title="Backoffice" />
      <Switch>
        <RedirectToDashboard path="/" component={Home} exact />
        <Route path="/forgot-password" component={ForgotPassword} exact />
        <ProtectedRoute path="/dashboard" component={Dashboard} exact />
        <ProtectedRoute path="/profile" component={Profile} exact />
        <Route path="/auth/verify" component={Verify} exact />
        <Route path="/createuser" component={CreateUser} exact />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default AppRouter;
