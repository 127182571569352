import React, { useEffect, useState } from 'react';
import {
  Modal, Icon, Header, List, Button, Breadcrumb,
} from 'semantic-ui-react';
import CustomForm from './CustomForm';
import { SuccessMessage } from './SuccessMessage';

export const Candidate = props => {
  const sections = [
    { key: '1', content: props.event_name },
    { key: '2', content: props.team_name },
    { key: '3', content: props.number_in_team },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);

  function _success() {
    setIsSuccessMessageOpen(true);
  }

  function closeAfterMessage() {
    setIsSuccessMessageOpen(false);
    setIsOpen(false);
  }

  return (
    <List.Item key={props.id} className="custom-list-item">
      <List.Content onClick={() => setIsOpen(true)}>
        <List.Header className="custom-list-header custom-list-header-icon">
          {`${props.number_in_team}`}
          {' '}
          <Icon name={props.status === 'active' ? 'check circle' : 'times circle'} color={props.status === 'active' ? 'green' : 'red'} />
        </List.Header>
      </List.Content>
      <Modal
        size="fullscreen"
        className="rtl-container"
        open={isOpen}
        onClose={() => isOpen(false)}
      >
        <Modal.Content className="rtl-container">
          <div className="custom-header-modal" style={{ marginBottom: 20 }}>
            {/* <Breadcrumb size='large' icon='left angle' sections={sections} /> */}
            <Header content={props.number_in_team} />
            <Icon onClick={() => setIsOpen(false)} name="close" />
          </div>


          <CustomForm
            close={() => setIsOpen(false)}
            field_options={[
              {
                type: 'select', name: 'candidate_status', placeholder: 'סטאטוס', initialValue: props.status ? props.status : '', options: props.statuses,
              },
              { type: 'hidden', name: 'candidate_id', initialValue: props.candidate_id },
              { type: 'hidden', name: 'event_name', initialValue: props.event_name },
              { type: 'hidden', name: 'remarks', initialValue: 'none' },
            ]}
            submitText={(
              <div className="wizard-bottom-btns-container">
                <Button type="button" onClick={() => setIsOpen(false)}>סגור</Button>
                <Button color="teal">עדכן</Button>
              </div>
)}
            apiCall={props.status ? 'updateCandidateStatus' : 'insertCandidateStatus'}
            onSuccess={() => _success()}
            shouldResetOnSuccess
          />
          <SuccessMessage
            open={isSuccessMessageOpen}
            onClose={() => closeAfterMessage()}
            message="המתגבש עודכן בהצלחה!"
          />
        </Modal.Content>
      </Modal>
    </List.Item>
  );
};
