// import { URL } from '../config';
import axios from 'axios';
import { validation } from '../utils/validation';
import translateApiErros from '../utils/translateApiErros';
import getTypeToSearch from '../utils/getTypeToSearch';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const client = axios.create({
  baseURL: 'http://gibush.westeurope.cloudapp.azure.com:3000', // 'http://gibush-westeurope:8080/'
});

const api = {
  login: async (email, password) => {
    const error = validation.login(email, password);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('/auth/login', {
        email,
        password,
      }, headers);
      client.defaults.headers.common.Authorization = data.data.token;
      return data;
    } catch ({ response }) {
      if (response && response.data.code)
        return translateApiErros.login(response.data.code);


      return translateApiErros.login('default');
    }
  },

  logout: async () => {
    try {
      await client.get('/auth/logout');
      return true;
    } catch ({ response }) {
      return false;
    }
  },

  forgotPassword: () => {

  },

  sendImage: async files => {
    const formData = new FormData();
    formData.append('file', files[0].file);

    try {
      const result = await client.post('/parse-pdf-to-json', formData,
        {
          'Content-Type': 'multipart/form-data',
        });

      return result.data.data;
    } catch (err) {
      console.log(err);
      return {};
    }
  },

  getUserList: async query => {
    const modifiedQuery = getTypeToSearch(query);

    try {
      const { data } = await client.get(`/user?${modifiedQuery}`);

      return data;
    } catch ({ response }) {
      return { status: response.status };
    }
  },

  getCandidateList: async query => {
    try {
      const { data } = await client.get(`/candidate_in_team?${query}`);
      return data;
    } catch ({ response }) {
      return { data: { error: true } };
    }
  },

  getCandidateById: async query => {
    try {
      const { data } = await client.get(`/candidate_by_event?${query}`);
      return data;
    } catch ({ response }) {
      return { data: { error: true } };
    }
  },

  isLoggedIn: async () => {
    try {
      await client.get('/auth/is_logged_in');
      return true;
    } catch ({ response }) {
      return false;
    }
  },

  inviteUser: async (email, first_name, last_name, role_name) => {
    const error = validation.inviteUser(email, first_name, last_name, role_name);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('/auth/invite', {
        email,
        first_name,
        last_name,
        role_name,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.createUser(data.code);

      return translateApiErros.createUser('default');
    }
  },

  createUser: async (email, phone_number, password, password_confirmation) => {
    const error = validation.createUser(email, phone_number, password, password_confirmation);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('/user/register', {
        email,
        phone_number,
        password,
        password_confirmation,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.createUser(data.code);

      return translateApiErros.createUser('default');
    }
  },

  createEvent: async (name, type, unit) => {
    const error = validation.createEvent(name, type, unit);
    if (error.error)
      return error;

    const date = new Date();

    try {
      const { data } = await client.post('/events', {
        name,
        type,
        unit,
        date,
        is_active: true,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.createEvent(data.code);

      return translateApiErros.createEvent('default');
    }
  },

  getEvents: async () => {
    try {
      const { data } = await client.get('/events');
      return data;
    } catch ({ response }) {
      return { status: response.status, error: true };
    }
  },

  createStaff: async (details, user_id) => {
    try {
      const { data } = await client.post('/staff', {
        user_id,
        details,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;
      if (data.data)
        return translateApiErros.createStaff(data.data);

      return translateApiErros.createStaff('default');
    }
  },

  staffToEvent: async (event_name, staff_id) => {
    try {
      const { data } = await client.post('/event_staff', {
        event_name,
        staff_id,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;
      if (data.code === 'Conflict')
        return { error: false };

      if (data.code)
        return translateApiErros.staffToEvent(data.code);

      return translateApiErros.staffToEvent('default');
    }
  },

  getStaffByUserId: async user_id => {
    try {
      const { data } = await client.get(`/staff_by_user_id?user_id=${user_id}`);
      return data;
    } catch ({ response }) {
      return false;
    }
  },

  connectUserToEvent: async (details, event_name, team_id, user_id) => {
    const error = validation.connectUserToEvent(details, event_name, team_id);
    if (error.error)
      return error;
    let staff;
    // check if the staff already exists
    const { data } = await api.getStaffByUserId(user_id);
    staff = data;
    let should_update = null;
    if (!staff) {
      const { data } = await api.createStaff(details, user_id);
      staff = data;
      if (staff.error)
        return staff;
    } else {
      should_update = await api.isStaffRelateToTeam(staff.id, event_name);
    }

    const staff_to_event = await api.staffToEvent(event_name, staff.id);
    if (staff_to_event.error)
      return staff_to_event;

    const staff_to_team = await api[should_update ? 'updateStaffToTeam' : 'relateUserToTeam'](staff.id, event_name, team_id, should_update.data);
    if (staff_to_team.error)
      return staff_to_team;

    return true;
  },

  // getUserRelations: async (user) => {
  //   try {
  //     const isStaff = await api.getStaffByUserId(user.id);
  //     console.log(isStaff);
  //   } catch ({ response }) {
  //     console.log(response);
  //   }
  // },

  getTeams: async query => {
    try {
      const { data } = await client.get(`/team?${query}`);
      return data;
    } catch ({ response }) {
      const { data } = response;
      if (data.code === 'NotFound')
        return { data: [] };

      return { data: { error: true } };
    }
  },

  createCandidate: async (personal_number, event_name) => {
    const error = validation.createCandidate(personal_number, event_name);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('/candidate', {
        personal_number,
        event_name,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.createCandidate(data.code);

      return translateApiErros.createCandidate('default');
    }
  },

  candidateToTeam: async (number_in_team, team_id, candidate_id, event_name) => {
    const error = validation.candidateToTeam(number_in_team, team_id, candidate_id, event_name);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('/candidate_in_team', {
        number_in_team,
        team_id,
        candidate_id,
        event_name,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.candidateToTeam(data.code);

      return translateApiErros.candidateToTeam('default');
    }
  },

  getStaffByEvent: async event_name => {
    const { data } = await client.get(`/event_staff?event_name=${event_name}`);
    return data;
  },

  candidateToStaff: async (staff_id, event_name, candidate_id) => {
    const error = validation.candidateToStaff(staff_id, event_name, candidate_id);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('candidate_staff', {
        staff_id,
        event_name,
        candidate_id,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.candidateToTeam(data.code);

      return translateApiErros.candidateToTeam('default');
    }
  },

  getTeamCandidates: async (event_name, team_id) => {
    try {
      const { data } = await client.get(`/candidate_list_in_team_by_event?event_name=${event_name}&team_id=${team_id}&status=active`);
      return data;
    } catch ({ response }) {
      return false;
    }
  },

  createRole: async name => {
    try {
      const { data } = await client.post('/role', {
        name,
      }, headers);
      return data;
    } catch ({ response }) {
      return { data: { error: true } };
    }
  },

  addTeam: async (team_name, event_name) => {
    const error = validation.addTeam(team_name, event_name);
    if (error.error)
      return error;

    try {
      const { data } = await client.post('/team', {
        team_name,
        event_name,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.addTeam(data.code);

      return translateApiErros.addTeam('default');
    }
  },

  addNewCandidate: async (personal_number, number_in_team, staff_id, event_name, team_id) => {
    const error = validation.addNewCandidate(personal_number, number_in_team, staff_id.toString());
    if (error.error)
      return error;

    const candidate = await api.createCandidate(personal_number, event_name);
    if (candidate.error)
      return candidate;

    const candidate_to_team = await api.candidateToTeam(number_in_team, team_id, candidate.data.candidate_id, event_name);
    if (candidate_to_team.error)
      return candidate_to_team;

    const candidate_to_staff = await api.candidateToStaff(staff_id.toString(), event_name, candidate.data.candidate_id);
    if (candidate_to_staff.error)
      return candidate_to_staff;

    return candidate;
  },

  updateCandidateStatus: async (candidate_status, candidate_id, event_name, remarks) => {
    try {
      const { data } = await client.put(`/candidate_status_in_event/${candidate_id}`, {
        candidate_status,
        event_name,
        remarks,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.changeCandidateStatus(data.code);

      return translateApiErros.changeCandidateStatus('default');
    }
  },

  insertCandidateStatus: async (candidate_status, candidate_id, event_name, remarks) => {
    try {
      const { data } = await client.post('/candidate_status_in_event', {
        candidate_status,
        candidate_id,
        event_name,
        remarks,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.changeCandidateStatus(data.code);

      return translateApiErros.changeCandidateStatus('default');
    }
  },

  getCandidateStatus: async () => {
    try {
      const { data } = await client.get('/candidate_status');
      return data;
    } catch ({ response }) {
      return { data: { error: true } };
    }
  },

  relateUserToTeam: async (staff_id, event_name, team_id) => {
    try {
      const { data } = await client.post('/team_staff', {
        staff_id: staff_id.toString(),
        event_name,
        team_id,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.relateUserToTeam(data.code);

      return translateApiErros.relateUserToTeam('default');
    }
  },

  updateStaffToTeam: async (staff_id, event_name, team_id, extraData) => {
    try {
      const { data } = await client.put(`/team_staff/${staff_id}/${extraData.event_name}/${extraData.team_id}`, {
        staff_id: staff_id.toString(),
        event_name,
        team_id,
      }, headers);

      return data;
    } catch ({ response }) {
      const { data } = response;

      if (data.code)
        return translateApiErros.relateUserToTeam(data.code);

      return translateApiErros.relateUserToTeam('default');
    }
  },

  isStaffRelateToTeam: async (staff_id, event_name) => {
    try {
      const { data } = await client.get(`/team_staff?staff_id=${staff_id}&event_name=${event_name}`);
      return data;
    } catch ({ response }) {
      return null;
    }
  },
};

export default api;
