import React from 'react';
import {
  Header, Message, Container, Grid,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import CustomForm from './CustomForm';

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this._success = this._success.bind(this);
  }

  _success() {
    this.props.history.push('/dashboard');
  }

  render() {
    return (
      <Container>
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column className="form-container-width">
            <Header as="h2" color="teal" textAlign="center">
          התחבר למערכת
            </Header>
            <CustomForm
              field_options={[
                {
                  type: 'text', name: 'email', placeholder: 'אימייל', initialValue: '', icon: 'envelope',
                },
                {
                  type: 'password', name: 'password', placeholder: 'סיסמא', initialValue: '', icon: 'lock',
                },
              ]}
              submitText="התחבר"
              apiCall="login"
              onSuccess={this._success}
            />
            {/* <Message> */}
            {/*  <Link to="/forgot-password">שכחתי סיסמא</Link> */}
            {/* </Message> */}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default LoginForm;
