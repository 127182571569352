import React from 'react';
import {
  Header, Message, Button, Container, Grid, Modal, Icon,
} from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { validation } from '../utils/validation';
import CustomForm from '../components/CustomForm';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
      error: {},
      openSuccessMessage: false,
    };
    this._submit = this._submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._errorHandler = this._errorHandler.bind(this);
    this._success = this._success.bind(this);
    this.handleClosingSuccessMessage = this.handleClosingSuccessMessage.bind(this);
  }

  handleChange(e) {
    this.setState({ email: e.target.value });
  }

  _errorHandler(error) {
    this.setState({ loading: false, error });
  }

  async _submit(e) {
    e.preventDefault();
    await this.setState({ loading: true, error: {} });
    const { email } = this.state;

    const error = validation.forgotPassword(email);
    if (error.error)
      return this._errorHandler(error);

    setTimeout(() => {
      this._success();
    }, 1000);
  }

  _success() {
    this.setState({ loading: false, openSuccessMessage: true });
  }

  async handleClosingSuccessMessage() {
    await this.setState({ openSuccessMessage: false });
    // Redirect
    this.props.history.push('/');
  }

  render() {
    return (
      <Container>
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column className="form-container-width">
            <Header as="h2" color="teal" textAlign="center">
            איפוס סיסמא
            </Header>
            <CustomForm
              field_options={[
                {
                  type: 'text', name: 'email', placeholder: 'אימייל', initialValue: '', icon: 'envelope',
                },
              ]}
              submitText="שלח לי קישור לאיפוס סיסמא"
              validationFunc="forgotPassword"
              onSuccess={this._success}
            />
            <Message>
              <Link to="/">התחבר</Link>
            </Message>
          </Grid.Column>
          <Modal
            open={this.state.openSuccessMessage}
            onClose={this.handleClosingSuccessMessage}
          >
            <Modal.Content>
              <Header textAlign="right">הקישור נשלח אל האימייל שהוזן, אנא אפס את סיסמתך ונסה שנית.</Header>
            </Modal.Content>
            <Modal.Actions>
              <Button color="blue" attached="left" onClick={this.handleClosingSuccessMessage} inverted>
                <Icon name="checkmark" />
                {' '}
אוקיי
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(ForgotPassword);
