import React from 'react';
import ProfileUpdateForm from '../components/ProfileUpdateForm';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div>
        <ProfileUpdateForm />
      </div>
    );
  }
}

export default Profile;
