import React, { useState, useEffect } from 'react';
import {
  Icon, Modal, Header, Segment, Loader,
} from 'semantic-ui-react';
import api from '../api';
import CustomForm from './CustomForm';

const UserProfile = props => {
  const [ready, setReady] = useState(false);

  async function fetchUserData() {
    // await api.getUserRelations(props.user);

    setReady(true);
  }

  async function getTeamsOfEvent(event_name) {
    const { data } = await api.getTeams(`event_name=${event_name}`);
    // transfer to selection array
    if (data.error)
      return [{ key: '', value: '', text: '' }];

    const arr = [];
    for (let i = 0; i < data.length; i++) {
      const newObj = {};
      newObj.key = data[i].id;
      newObj.value = data[i].id;
      newObj.text = data[i].team_name;
      arr.push(newObj);
    }

    return arr;
  }

  useEffect(() => {
    fetchUserData();
  }, [props.user]);

  function _success() {

  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      {props.user ? (
        <Modal.Content>
          {ready ? (
            <>
              <div className="custom-header-modal">
                <Header>
                  {props.user.first_name}
                  {' '}
                  {props.user.last_name}
                </Header>
                <Icon onClick={props.onClose} name="close" />
              </div>
              <CustomForm
                close={props.onClose}
                isModalForm
                field_options={[
                  {
                    type: 'text', name: 'details', placeholder: 'פרטים', initialValue: '', icon: 'envelope',
                  },
                  {
                    type: 'select', name: 'event_name', placeholder: 'שם הגיבוש', initialValue: '', state_property_relation: 'team_id', onChangeEndFunc: value => getTeamsOfEvent(value), options: props.events,
                  },
                  {
                    type: 'select', name: 'team_id', isRational: true, placeholder: 'צוות', initialValue: '', options: [{ key: '', value: '', text: '' }],
                  },
                  { type: 'hidden', name: 'user_id', initialValue: props.user.id },
                ]}
                submitText={['סגור', 'שייך משתמש לגיבוש']}
                apiCall="connectUserToEvent"
                onSuccess={() => _success()}
                shouldResetOnSuccess
              />
            </>
          ) : (
            <Segment inverted>
              <Loader active inline="centered" content="טוען" />
            </Segment>
          )}

        </Modal.Content>
      ) : null}

    </Modal>
  );
};

export default UserProfile;
