import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from '../components/LoginForm';

const Home = props => (
  <div>
    <LoginForm history={props.history} />
  </div>
);

export default withRouter(Home);
