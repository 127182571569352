import React, { useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import api from '../../api';

function CandidateRow(props) {
  const options = (props.staff || [])
    .map(staff => ({ value: staff.staff_id, label: `${staff.staff_first_name} ${staff.staff_last_name}` }));

  const [shirtNumber, setShirtNumber] = useState(props.shirtNumber);
  const [candidateId, setCandidateId] = useState(props.candidateId);
  const [didSent, setDidSent] = useState(false);
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(options[0]);

  async function onClick() {
    try {
      setIsPending(true);
      setError('');
      await api.addNewCandidate(candidateId, shirtNumber, selectedStaff.value, props.eventName, props.teamId);
      setDidSent(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsPending(false);
    }
  }

  return (
    <tr>
      <td data-label="Staff">
        <Dropdown
          options={options}
          onChange={selected => setSelectedStaff(selected)}
          value={selectedStaff}
          placeholder="Select an option"
        />
      </td>
      <td data-label="Number in team">
        <div className="ui input">
          <input
            type="text"
            value={shirtNumber}
            onChange={e => {
              setShirtNumber(e.target.value);
            }}
          />
        </div>
      </td>
      <td data-label="Candidate ID">
        <div className="ui input">
          <input
            type="text"
            value={candidateId}
            onChange={e => {
              setCandidateId(e.target.value);
            }}
          />
        </div>
      </td>
      <td data-label="Action">
        {
          error
            ? (
              <div className="ui warning message">
                <p>{error}</p>
              </div>
            ) : <ActionButton didSent={didSent} onClick={onClick} isPending={isPending} />
        }
      </td>
    </tr>
  );
}

function ActionButton(props) {
  return (
    props.didSent ? <div>התווסף בהצלחה</div>
      : (
        <button disabled={props.isPending} type="button" className="ui primary button" onClick={props.onClick}>
          +
        </button>
      )
  );
}

export default CandidateRow;
