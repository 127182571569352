import React from 'react';
import {
  Modal, Icon, Button, Header,
} from 'semantic-ui-react';
import CustomForm from './CustomForm';
import { SuccessMessage } from './SuccessMessage';

export class AddNewUser extends React.PureComponent {
  roleOptions = [
    { key: 'admin', value: 'admin', text: 'admin' },
    { key: 'evaluator', value: 'evaluator', text: 'evaluator' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      openSuccessMessage: false,
    };

    this._success = this._success.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClosingSuccessMessage = this.handleClosingSuccessMessage.bind(this);
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  handleOpen() {
    this.setState({ modalOpen: true });
  }

  handleClosingSuccessMessage() {
    this.setState({ openSuccessMessage: false });
  }

  _success() {
    this.setState({ openSuccessMessage: true });
  }

  render() {
    return (
      <Modal
        size="fullscreen"
        className="rtl-container"
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={(
          <Button
            floated="right"
            icon="add user"
            primary
            size="huge"
            circular
            onClick={this.handleOpen}
            className="custom-add-user-btn"
          />
      )}
      >
        <Modal.Content className="rtl-container">
          <div className="custom-header-modal">
            <Header>הוסף משתמש</Header>
            <Icon onClick={this.handleClose} name="close" />
          </div>
          <CustomForm
            close={this.handleClose}
            isModalForm
            field_options={[
              {
                type: 'text', name: 'email', placeholder: 'אימייל', initialValue: '', icon: 'envelope',
              },
              {
                type: 'text', name: 'first_name', placeholder: 'שם פרטי', initialValue: '', icon: 'user',
              },
              {
                type: 'text', name: 'last_name', placeholder: 'שם משפחה', initialValue: '', icon: 'user',
              },
              {
                type: 'select', name: 'role_name', placeholder: 'תפקיד', initialValue: '', item_addition_api_func: 'createRole', allowAdditions: true, options: this.roleOptions,
              },
            ]}
            submitText={['סגור', 'שלח']}
            apiCall="inviteUser"
            onSuccess={this._success}
            shouldResetOnSuccess
          />
          <SuccessMessage
            open={this.state.openSuccessMessage}
            onClose={this.handleClosingSuccessMessage}
            message="ההזמנה נשלחה בהצלחה!"
          />
        </Modal.Content>
      </Modal>
    );
  }
}

export default AddNewUser;
