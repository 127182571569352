import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import Users from '../components/Users';
import Candidates from '../components/Candidates';
import Events from '../components/Events';
import { About } from '../components/About';
import api from '../api';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'users',
      isAboutOpen: false,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this._logout = this._logout.bind(this);
    this._renderView = this._renderView.bind(this);
    this._gotoProfile = this._gotoProfile.bind(this);
  }

  handleMenuClick(active) {
    this.setState({ active });
  }

  _renderView() {
    let View;
    switch (this.state.active) {
      case 'users':
        View = Users;
        break;
      case 'candidates':
        View = Candidates;
        break;
      case 'events':
        View = Events;
        break;
      default:
        View = Users;
    }

    return <View />;
  }

  _gotoProfile() {
    this.props.history.push('/profile');
  }

  async _logout() {
    const success = await api.logout();
    if (success)
      this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <Menu className="menu-custom-container" inverted size="huge" fluid widths={4}>
          <Dropdown icon="align justify" fluid item direction="left">
            <Dropdown.Menu>
              {/* <Dropdown.Item onClick={this._gotoProfile}>
                פרופיל
              </Dropdown.Item> */}
              <Dropdown.Item onClick={() => this.setState({ isAboutOpen: true })}>
                 מידע
              </Dropdown.Item>
              <Dropdown.Item onClick={this._logout}>
                התנתק
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item
            name="users"
            onClick={() => this.handleMenuClick('users')}
            active={this.state.active === 'users'}
            className="custom-menu-item"
          >
            <div>
              <Icon name="users" />
            </div>
            <p>משתמשים</p>
          </Menu.Item>
          <Menu.Item
            name="candidates"
            onClick={() => this.handleMenuClick('candidates')}
            active={this.state.active === 'candidates'}
            className="custom-menu-item"
          >
            <div>
              <Icon name="users" />
            </div>
            <p>מתגבשים</p>
          </Menu.Item>
          <Menu.Item
            name="events"
            onClick={() => this.handleMenuClick('events')}
            active={this.state.active === 'events'}
            className="custom-menu-item"
          >
            <div>
              <Icon name="users" />
            </div>
            <p>גיבושים</p>
          </Menu.Item>
        </Menu>

        <div>
          {this._renderView()}
        </div>
        <About close={() => this.setState({ isAboutOpen: false })} open={this.state.isAboutOpen} />
      </div>
    );
  }
}

export default withRouter(Dashboard);
