import React, { useEffect, useState } from 'react';
import {
  Modal, Icon, Button, Header, Message, Loader,
} from 'semantic-ui-react';
import CustomForm from './CustomForm';
import api from '../api';
import { SuccessMessage } from './SuccessMessage';

export const AddNewCandidate = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(false);
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);
  const [staff, setStaff] = useState([]);

  async function fetchStaff() {
    try {
      const { data } = await api.getStaffByEvent(props.event_name);

      // arrange the data for selection input
      const staff_selection = [];
      for (let i = 0; i < data.length; i++) {
        const newObj = {};
        newObj.key = data[i].staff_id;
        newObj.value = data[i].staff_id;
        newObj.text = `${data[i].staff_first_name} ${data[i].staff_last_name}`;
        staff_selection.push(newObj);
      }

      setStaff(staff_selection);
      setIsReady(true);
    } catch (err) {
      setError(true);
      setIsReady(true);
    }
  }

  function _success(data) {
    setIsSuccessMessageOpen(true);
  }

  useEffect(() => {
    fetchStaff();
  }, [isOpen]);


  return (
    <Modal
      size="fullscreen"
      className="rtl-container"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={(
        <Button icon labelPosition="right" size="tiny" primary onClick={() => setIsOpen(true)}>
            הוסף מתגבש
          <Icon name="user plus" />
        </Button>
        )}
    >
      <Modal.Content className="rtl-container">
        <div className="custom-header-modal">
          <Header>הוסף מתגבש</Header>
          <Icon onClick={() => setIsOpen(false)} name="close" />
        </div>
        {isReady ? (
          error ? (
            <Message
              negative
              content="משהו השתבש כשניסינו למשוך את המעריכים, אנא נסה שנית"
            />
          ) : (
            <CustomForm
              close={() => setIsOpen(false)}
              field_options={[
                {
                  type: 'numeric', name: 'personal_number', placeholder: 'מספר אישי', initialValue: '', icon: 'user',
                }, // number_in_team
                {
                  type: 'numeric', name: 'number_in_team', placeholder: 'מספר בקבוצה זו', initialValue: '', icon: 'clipboard',
                },
                {
                  type: 'select', name: 'staff_id', placeholder: 'שייך למעריך', initialValue: '', options: staff,
                },
                {
                  type: 'hidden', name: 'event_name', placeholder: '', initialValue: props.event_name,
                },
                {
                  type: 'hidden', name: 'team_id', placeholder: '', initialValue: props.team_id,
                },
              ]}
              submitText={['סגור', 'הוסף']}
              apiCall="addNewCandidate"
              onSuccess={data => _success(data)}
              isModalForm
              shouldResetOnSuccess
            />
          )
        ) : (
          <Loader />
        )}
        <SuccessMessage
          open={isSuccessMessageOpen}
          onClose={() => setIsSuccessMessageOpen(false)}
          message="מתגבש התווסף בהצלחה!"
        />
      </Modal.Content>
    </Modal>
  );
};
