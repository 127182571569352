export default (type, content, pointing) => {
  const obj = { content };
  if (pointing != null)
    obj.pointing = pointing;
  return {
    error: true,
    type,
    obj,
  };
};
