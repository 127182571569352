import React from 'react';
import {
  Container, Segment, Message, List, Input, Sticky, Loader,
} from 'semantic-ui-react';
import api from '../api';
import AddNewUser from './AddNewUser';
import UserProfile from './UserProfile';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isStick: false,
      search: '',
      loading: true,
      initialLoading: true,
      userModal: false,
      activeUser: null,
      events: [],
    };
    this._renderRow = this._renderRow.bind(this);
    this._onStick = this._onStick.bind(this);
    this._onUnstick = this._onUnstick.bind(this);
    this._getUsers = this._getUsers.bind(this);
    this._submit = this._submit.bind(this);
    this._closeUserModal = this._closeUserModal.bind(this);
    this._setActiveUser = this._setActiveUser.bind(this);
    this._getEvents = this._getEvents.bind(this);
  }

  _setActiveUser(user) {
    this.setState({ activeUser: user, userModal: true });
  }

  _renderRow(user) {
    return (
      <List.Item key={user.id} className="custom-list-item" onClick={() => this._setActiveUser(user)}>
        <List.Content>
          <List.Header className="custom-list-header">{`${user.role_name} - ${user.first_name} ${user.last_name}`}</List.Header>
        </List.Content>
      </List.Item>
    );
  }

  _onStick() {
    if (!this.state.isStick)
      this.setState({ isStick: true });
  }

  _onUnstick() {
    if (this.state.isStick)
      this.setState({ isStick: false });
  }

  _submit(e) {
    e.preventDefault();
    if (!this.state.loading)
      this._getUsers();
  }

  async _getUsers() {
    const { search } = this.state;
    await this.setState({ loading: true });

    const response = await api.getUserList(search);
    if (response.status === 'success') {
      // update
      let users;
      if (response.data.constructor === Array)
        users = response.data;
      else
        users = [response.data];


      this.setState({ users, loading: false });
      return;
    }

    this.setState({ loading: false });
  }

  async _getEvents() {
    const events = await api.getEvents();
    if (events.error)
      return;
    // Format the events objects to a selection array
    const { data } = events;
    const modifiedArray = [];

    for (let i = 0; i < data.length; i++) {
      const newObj = {};
      newObj.key = data[i].id;
      newObj.value = data[i].name;
      newObj.text = data[i].name;

      modifiedArray.push(newObj);
    }

    this.setState({ events: modifiedArray });
  }

  async componentDidMount() {
    // Get all users
    await this._getUsers();
    await this._getEvents();
    this.setState({ initialLoading: false });
  }

  _closeUserModal() {
    this.setState({ userModal: false, activeUser: null });
  }

  render() {
    return (
      <Container style={{ marginTop: '5%' }}>
        <Sticky
          onStick={this._onStick}
          onUnstick={this._onUnstick}
          className={`${this.state.isStick ? 'custom-sticky-container-input-stick' : ''}`}
        >
          <Segment
            className={`${this.state.isStick ? '' : 'custom-container-input-unstick'}`}
            inverted={this.state.isStick}
            style={this.state.isStick ? { borderRadius: 0 } : {}}
            as="form"
            onSubmit={this._submit}
          >
            <Input loading={this.state.loading} disabled={this.state.loading} onBlur={this._getUsers} className="custom-search-input" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} action={{ icon: 'search' }} placeholder="חיפוש..." fluid />
          </Segment>
        </Sticky>
        <List relaxed divided>
          {this.state.initialLoading ? (
            <Loader active inline="centered" />
          ) : (
            this.state.users.length === 0 ? (
              <Message warning>
                לא הצלחנו למצוא משתמש מתאים
              </Message>
            ) : this.state.users.map(user => this._renderRow(user))
          )}

          <UserProfile open={this.state.userModal} events={this.state.events} user={this.state.activeUser} onClose={this._closeUserModal} />
        </List>
        <AddNewUser />
      </Container>
    );
  }
}

export default Users;
