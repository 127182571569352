import React, { useEffect, useState } from 'react';
import {
  Container, List, Loader, Message, Modal, Header, Icon,
} from 'semantic-ui-react';
import CreateEvent from './CreateEvent';
import api from '../api';

const Event = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);

  async function init() {
    // const { data } = api.getCandidateById(`event_name=${props.event_name}&team_id=${props.team_id}&candidate_id=${props.candidate_id}`);
  }

  useEffect(() => {
    init();
  }, [isOpen]);

  return (
    <List.Item key={props.id} className="custom-list-item">
      <List.Content onClick={() => setIsOpen(true)}>
        <List.Header className="custom-list-header custom-list-header-icon">
          {`${props.name}`}
          {' '}
          <Icon name={props.is_active ? 'check circle' : 'times circle'} color={props.is_active ? 'green' : 'red'} />
        </List.Header>
      </List.Content>
      <Modal
        size="fullscreen"
        className="rtl-container"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Modal.Content className="rtl-container">
          <div className="custom-header-modal">
            <Header>{props.name}</Header>
            <Icon onClick={() => setIsOpen(false)} name="close" />
          </div>

        </Modal.Content>
      </Modal>
    </List.Item>
  );
};

const Events = props => {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(false);
  const [events, setEvents] = useState([]);

  async function fetchEvents() {
    const events = await api.getEvents();
    if (events.error)
      return setError(true);

    setEvents(events.data);
  }

  useEffect(() => {
    fetchEvents();
    setIsReady(true);
  }, []);

  return (
    <Container>
      <CreateEvent />
      {isReady ? (
        error ? (
          <Message negative content="לא הצלחנו למשוך את הגיבושים, אנא נסה שוב." />
        ) : (
          <List relaxed divided>
            {events.map(e => <Event key={e.id} {...e} />)}
          </List>
        )
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default Events;
