import strings from '../strings';
import buildError from './buildError';

export function validEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    return true;

  return false;
}

export function validPhoneNumber(phone) {
  if (/^[0-9]{7,10}$/.test(phone))
    return true;

  return false;
}

export const validation = {
  login: (email, password) => {
    let error = {};
    if (email.length === 0)
      error = buildError('email', strings.EMPTY_EMAIL, 'below');


    else if (!(validEmail(email)))
      error = buildError('email', strings.INVALID_EMAIL, 'below');


    else if (password.length === 0)
      error = buildError('password', strings.EMPTY_PASSWORD, null);


    else if (password.length <= 5)
      error = buildError('password', strings.PASSWORD_TOO_SHORT, null);


    return error;
  },

  forgotPassword: email => {
    let error = {};
    if (email.length === 0)
      error = buildError('email', strings.EMPTY_EMAIL, 'below');


    else if (!validEmail(email))
      error = buildError('email', strings.INVALID_EMAIL, 'below');


    return error;
  },

  profileUpdate: (first_name, last_name, phone_number) => {
    let error = {};
    if (first_name.length === 0)
      error = buildError('first_name', strings.EMPTY_FIRST_NAME, 'below');

    else if (first_name.length < 2)
      error = buildError('first_name', strings.FIRST_NAME_SHORT, 'below');

    else if (last_name.length === 0)
      error = buildError('last_name', strings.EMPTY_LAST_NAME, 'below');

    else if (last_name.length < 2)
      error = buildError('last_name', strings.LAST_NAME_SHORT, 'below');

    else if (phone_number.length === 0)
      error = buildError('phone_number', strings.EMPTY_PHONE_NUMBER, 'below');

    else if (!(validPhoneNumber(phone_number)))
      error = buildError('phone_number', strings.INVALID_PHONE_NUMBER, 'below');

    return error;
  },

  changePassword: (current_password, new_password, reapet_password) => {
    let error = {};
    if (current_password.length === 0)
      error = buildError('current_password', strings.EMPTY_PASSWORD, 'below');

    else if (current_password.length <= 5)
      error = buildError('current_password', strings.PASSWORD_TOO_SHORT, 'below');

    else if (new_password.length === 0)
      error = buildError('new_password', strings.EMPTY_PASSWORD, 'below');

    else if (new_password.length <= 5)
      error = buildError('new_password', strings.PASSWORD_TOO_SHORT, 'below');

    else if (reapet_password !== new_password)
      error = buildError('reapet_password', strings.PASSWORDS_DONT_MATCH, 'below');

    return error;
  },

  inviteUser: (email, first_name, last_name, role_name) => {
    let error = {};

    if (email.length === 0)
      error = buildError('email', strings.EMPTY_EMAIL, 'below');

    else if (!(validEmail(email)))
      error = buildError('email', strings.INVALID_EMAIL, 'below');

    else if (first_name.length === 0)
      error = buildError('first_name', strings.EMPTY_FIRST_NAME, 'below');

    else if (first_name.length < 2)
      error = buildError('first_name', strings.FIRST_NAME_SHORT, 'below');

    else if (last_name.length === 0)
      error = buildError('last_name', strings.EMPTY_LAST_NAME, 'below');

    else if (last_name.length < 2)
      error = buildError('last_name', strings.LAST_NAME_SHORT, 'below');

    else if (role_name === '')
      error = buildError('role_name', strings.INVALID_ROLE, 'below');

    return error;
  },

  createUser: (email, phone_number, password, password_confirmation) => {
    let error = {};
    if (email.length === 0)
      error = buildError('email', strings.EMPTY_EMAIL, 'below');

    else if (!(validEmail(email)))
      error = buildError('email', strings.INVALID_EMAIL, 'below');

    else if (phone_number.length === 0)
      error = buildError('phone_number', strings.EMPTY_PHONE_NUMBER, 'below');

    else if (!(validPhoneNumber(phone_number)))
      error = buildError('phone_number', strings.INVALID_PHONE_NUMBER, 'below');

    else if (password.length === 0)
      error = buildError('password', strings.EMPTY_PASSWORD, null);

    else if (password.length <= 5)
      error = buildError('password', strings.PASSWORD_TOO_SHORT, null);

    else if (password !== password_confirmation)
      error = buildError('password', strings.PASSWORDS_DONT_MATCH, null);

    return error;
  },

  createEvent: (name, type, unit) => {
    let error = {};
    if (name.length === 0)
      error = buildError('name', strings.EMPTY_EVENT_NAME, 'below');

    else if (name.length < 2)
      error = buildError('name', strings.EVENT_NAME_SHORT, 'below');

    else if (type.length === 0)
      error = buildError('type', strings.EMPTY_EVENT_TYPE, 'below');

    else if (type.length < 2)
      error = buildError('type', strings.EVENT_TYPE_SHORT, 'below');

    else if (unit.length === 0)
      error = buildError('unit', strings.EMPTY_EVENT_UNIT, 'below');

    else if (unit.length < 2)
      error = buildError('unit', strings.EVENT_UNIT_SHORT, 'below');

    return error;
  },

  connectUserToEvent: (details, event_name, team_name) => {
    let error = {};
    if (details.length === 0)
      error = buildError('details', strings.EMPTY_FIELD, 'below');

    else if (details.length < 2)
      error = buildError('details', strings.DETAILS_TOO_SHORT, 'below');

    else if (event_name.length === 0)
      error = buildError('event_name', strings.EMPTY_FIELD, 'below');

    else if (team_name.length === 0)
      error = buildError('general', strings.EMPTY_FIELD, 'below');

    return error;
  },

  createCandidate: (personal_number, event_name) => {
    let error = {};
    if (personal_number.length === 0)
      error = buildError('personal_number', strings.EMPTY_FIELD, 'below');

    else if (personal_number.length > 10)
      error = buildError('personal_number', strings.PERSONAL_NUMBER_WRONG_LENGTH, 'below');

    else if (event_name.length === 0)
      error = buildError('event_name', strings.EMPTY_FIELD, 'below');

    return error;
  },

  candidateToTeam: (number_in_team, team_id, candidate_id, event_name) => {
    let error = {};
    if (number_in_team.length === 0)
      error = buildError('number_in_team', strings.EMPTY_FIELD, 'below');

    else if (team_id.length === 0)
      error = buildError('general', strings.EMPTY_FIELD, 'below');

    else if (candidate_id.length === 0)
      error = buildError('general', strings.GENERAL_ERROR_MESSAGE, 'below');

    else if (event_name.length === 0)
      error = buildError('general', strings.GENERAL_ERROR_MESSAGE, 'below');

    return error;
  },

  candidateToStaff: (staff_id, event_name, candidate_id) => {
    let error = {};
    if (staff_id.length === 0)
      error = buildError('staff_id', strings.EMPTY_FIELD, 'below');

    else if (event_name.length === 0)
      error = buildError('general', strings.GENERAL_ERROR_MESSAGE, 'below');

    else if (candidate_id.length === 0)
      error = buildError('general', strings.GENERAL_ERROR_MESSAGE, 'below');

    return error;
  },

  addTeam: (team_name, event_name) => {
    let error = {};
    if (team_name.length === 0)
      error = buildError('team_name', strings.EMPTY_FIELD, 'below');

    else if (event_name.length === 0)
      error = buildError('general', strings.GENERAL_ERROR_MESSAGE, 'below');

    return error;
  },

  addNewCandidate: (personal_number, number_in_team, staff_id) => {
    let error = {};
    if (personal_number.length === 0)
      error = buildError('personal_number', strings.EMPTY_FIELD, 'below');

    else if (personal_number.length > 10)
      error = buildError('personal_number', strings.PERSONAL_NUMBER_WRONG_LENGTH, 'below');

    else if (number_in_team.length === 0)
      error = buildError('number_in_team', strings.EMPTY_FIELD, 'below');

    else if (staff_id.length === 0)
      error = buildError('staff_id', strings.EMPTY_FIELD, 'below');

    return error;
  },
};
