import React from 'react';
import { Header, Container, Grid } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import CustomForm from '../components/CustomForm';

class CreateUser extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      createSuccess: false,
    };
    this._success = this._success.bind(this);
  }

  _success() {
    this.setState({ createSuccess: true });
  }

  render() {
    return (
      <Container>
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column className="form-container-width">
            {this.state.createSuccess ? (
              <>
                <Header as="h2" color="teal" textAlign="center">
              משתמש נוצר בהצלחה, כל מה שנותר הוא להוריד את האפליקצייה!
                </Header>
                <Container>
              LOGO WITH LINK TOO APP STORE
                </Container>
              </>
            ) : (
              <>
                <Header as="h2" color="teal" textAlign="center">
              צור משתמש
                </Header>
                <CustomForm
                  field_options={[
                    {
                      type: 'text', name: 'email', placeholder: 'אימייל', initialValue: '', icon: 'envelope',
                    },
                    {
                      type: 'numeric', name: 'phone_number', placeholder: 'מספר פלאפון', initialValue: '', icon: 'phone',
                    },
                    {
                      type: 'password', name: 'password', placeholder: 'סיסמא', initialValue: '', icon: 'lock',
                    },
                    {
                      type: 'password', name: 'password_confirmation', placeholder: 'חזור שנית על הסיסמא', initialValue: '', icon: 'lock',
                    },
                  ]}
                  submitText="צור משתמש"
                  apiCall="createUser"
                  onSuccess={this._success}
                />
              </>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(CreateUser);
