import React from 'react';
import {
  Button, Header, Modal, Icon,
} from 'semantic-ui-react';
import CustomForm from './CustomForm';
import { SuccessMessage } from './SuccessMessage';

class CreateEvent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      successModal: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this._success = this._success.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  _success() {
    this.setState({ successModal: true });
  }

  closeSuccessModal() {
    this.setState({ successModal: false, isOpen: false });
  }

  render() {
    return (
      <Modal onClose={this.handleClose} open={this.state.isOpen} trigger={<Button style={{ marginBottom: '5%' }} fluid primary onClick={this.handleOpen}>צור גיבוש</Button>}>
        <Modal.Content>
          <div className="custom-header-modal">
            <Header>צור גיבוש</Header>
            <Icon onClick={this.handleClose} name="close" />
          </div>
          <CustomForm
            close={this.handleClose}
            isModalForm
            field_options={[
              {
                type: 'text', name: 'name', placeholder: 'שם הגיבוש', initialValue: '', icon: 'envelope',
              },
              {
                type: 'text', name: 'type', placeholder: 'סוג הגיבוש', initialValue: '', icon: 'user',
              },
              {
                type: 'text', name: 'unit', placeholder: 'שם היחידה', initialValue: '', icon: 'user',
              },
            ]}
            submitText={['סגור', 'צור גיבוש']}
            apiCall="createEvent"
            onSuccess={this._success}
            shouldResetOnSuccess
          />
          <SuccessMessage message="הגיבוש נוצר בהצלחה! התחל להוסיף מעריכים" open={this.state.successModal} onClose={this.closeSuccessModal} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default CreateEvent;
