import React, { useState, useEffect } from 'react';
import {
  Icon, Container, Message, List, Loader,
} from 'semantic-ui-react';
import api from '../api';
import { AddNewCandidate } from './AddNewCandidate';
import MultipleCandidatesModal from '../modals/AddMultipleCandidates';
import { AddTeam } from './AddTeam';
import { Candidate } from './Candidate';

const Team = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(false);
  const [candidates, setCandidates] = useState([]);

  async function getActiveCandidates() {
    const { data } = await api.getCandidateList(`event_name=${props.event_name}&team_id=${props.id}&status=active`);
    if (data.error)
      return false;

    setCandidates(data);
    return true;
  }

  async function getDectiveCandidates() {
    const { data } = await api.getCandidateList(`event_name=${props.event_name}&team_id=${props.id}&status=inactive`);
    if (data.error)
      return false;

    setCandidates([...candidates, ...data]);
    return true;
  }

  function init() {
    const successActive = getActiveCandidates();
    const successDeactive = false;// getDectiveCandidates();

    if (!successActive && !successDeactive) {
      setError(true);
      setIsReady(true);
      return;
    }

    setIsReady(true);
  }

  useEffect(() => {
    init();
  }, [isOpen]);

  return (
    <div className="custom-accordion-container">
      <div className="custom-accordion-btn-container-small" onClick={() => setIsOpen(!isOpen)}>
        <p>{props.team_name}</p>
        <Icon name={isOpen ? 'angle up' : 'angle down'} />
      </div>

      {isOpen ? (
        isReady ? (
          error ? (
            <Message
              negative
              header="משהו השתבש במהלך הבקשה אנא נסה שנית"
            />
          ) : (
            <div className="custom-accordion-content-container">
              {candidates.length > 0 ? (
                <List relaxed divided>
                  {candidates.map(e => <Candidate statuses={props.statuses} {...e} key={e.id} team_name={props.team_name} />)}
                </List>
              ) : (
                <Message
                  warning
                  header="אין מתגבשים בצוות זה"
                />
              )}

              <AddNewCandidate event_name={props.event_name} team_id={props.id} team_name={props.team_name} />
              <MultipleCandidatesModal event_name={props.event_name} team_id={props.id} team_name={props.team_name} />
            </div>
          )

        ) : (
          <Loader />
        )

      ) : null}
    </div>
  );
};

const Event = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(false);
  const [teams, setTeams] = useState([]);

  async function fetchTeams() {
    const { data } = await api.getTeams(`event_name=${props.name}`);
    if (data.error) {
      setError(true);
      setIsReady(true);
      return;
    }

    setTeams(data);
    setIsReady(true);
  }

  useEffect(() => {
    fetchTeams();
  }, [isOpen]);

  return (
    <div className="custom-accordion-container">
      <div className="custom-accordion-btn-container" onClick={() => setIsOpen(!isOpen)}>
        <p>{props.name}</p>
        <Icon name={isOpen ? 'angle up' : 'angle down'} />
      </div>
      {isOpen ? (
        isReady ? (
          error ? (
            <Message
              negative
              header="משהו השתבש במהלך הבקשה אנא נסה שנית"
            />
          ) : (
            <div className="custom-accordion-content-container">
              {teams.length > 0 ? (
                teams.map(e => <Team {...e} key={e.id} statuses={props.statuses} />)
              ) : (
                <Message
                  warning
                  header="אין צוותים כרגע בגיבוש זה"
                />
              )}
              <AddTeam event_name={props.name} />
            </div>
          )

        ) : (
          <Loader />
        )

      ) : null}
    </div>
  );
};

class Candidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      initialLoading: true,
      events: [],
      statuses: [],
    };
    this._getEvents = this._getEvents.bind(this);
    this._getStatuses = this._getStatuses.bind(this);
  }

  async _getEvents() {
    const events = await api.getEvents();
    if (events.error)
      return;
    const { data } = events;

    this.setState({ events: data });
  }

  async _getStatuses() {
    const { data } = await api.getCandidateStatus();
    if (data.error)
      return;

    // arrange the array for selection
    const data_select_arr = [];
    for (let i = 0; i < data.length; i++) {
      const newObj = {};
      newObj.key = data[i].id;
      newObj.value = data[i].status;
      newObj.text = data[i].status;
      data_select_arr.push(newObj);
    }

    this.setState({ statuses: data_select_arr });
  }

  async componentDidMount() {
    // Get all relevent data
    await this._getEvents();
    await this._getStatuses();
    this.setState({ initialLoading: false });
  }

  _closeUserModal() {
    this.setState({ userModal: false, activeUser: null });
  }

  render() {
    return (
      <Container style={{ marginTop: '5%' }}>
        {this.state.initialLoading ? (
          <Loader active inline="centered" />
        ) : (
          this.state.events.map(e => <Event key={e.name} statuses={this.state.statuses} {...e} />)
        )}
      </Container>
    );
  }
}

export default Candidates;
