import React from 'react';
import {
  Icon, Button, Modal, Header,
} from 'semantic-ui-react';

export const SuccessMessage = props => (
  <Modal
    open={props.open}
    onClose={props.onClose}
    dimmer="inverted"
  >
    <Modal.Content>
      <Header textAlign="right">{props.message}</Header>
    </Modal.Content>
    <Modal.Actions>
      <Button color="blue" attached="left" onClick={props.onClose} inverted>
        <Icon name="checkmark" />
        {' '}
אוקיי
      </Button>
    </Modal.Actions>
  </Modal>
);
