import React, { useState } from 'react';
import {
  Modal, Icon, Button, Header,
} from 'semantic-ui-react';
import CustomForm from './CustomForm';
import { SuccessMessage } from './SuccessMessage';

export const AddTeam = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);

  function _success() {
    setOpenSuccessMessage(true);
  }

  return (
    <Modal
      size="fullscreen"
      className="rtl-container"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={(
        <Button
          size="tiny"
          primary
          content="הוסף צוות"
          onClick={() => setIsOpen(true)}
        />
    )}
    >
      <Modal.Content className="rtl-container">
        <div className="custom-header-modal">
          <Header>הוסף צוות</Header>
          <Icon onClick={() => setIsOpen(false)} name="close" />
        </div>
        <CustomForm
          close={() => setIsOpen(false)}
          isModalForm
          field_options={[
            {
              type: 'text', name: 'team_name', placeholder: 'שם הצוות', initialValue: '', icon: 'user',
            },
            { type: 'hidden', name: 'event_name', initialValue: props.event_name },
          ]}
          submitText={['סגור', 'הוסף']}
          apiCall="addTeam"
          onSuccess={() => _success()}
          shouldResetOnSuccess
        />
        <SuccessMessage
          open={openSuccessMessage}
          onClose={() => setOpenSuccessMessage(false)}
          message="צוות נוסף בהצלחה!"
        />
      </Modal.Content>
    </Modal>
  );
};

export default AddTeam;
