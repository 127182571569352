import { validEmail, validPhoneNumber } from './validation';

const serialize = function (obj, prefix) {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push((v !== null && typeof v === 'object')
        ? serialize(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }
  return str.join('&');
};

export default query => {
  let modifiedQuery;
  switch (query) {
    case validEmail(query):
      modifiedQuery = { email: query };
      break;
    case validPhoneNumber(query):
      modifiedQuery = { phoneNumber: query };
      break;
    case '':
      modifiedQuery = '';
      break;
    default:
      modifiedQuery = { $or: [{ first_name: { $like: `%${query}%` } }, { last_name: { $like: `%${query}%` } }] };
  }

  return serialize(modifiedQuery);
};
