import React from 'react';
import { Container, Grid, Message } from 'semantic-ui-react';
import ChangePassword from './ChangePassword';
import CustomForm from './CustomForm';

class ProfileUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: 'testing',
      role: 'admin',
    };
    this._success = this._success.bind(this);
  }

  _success() {

  }

  render() {
    return (
      <Container>
        <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
          <Grid.Column className="form-container-width">
            <CustomForm
              field_options={[
                {
                  type: 'text', name: 'username', initialValue: this.state.username, icon: 'user', readOnly: true,
                },
                {
                  type: 'text', name: 'first_name', placeholder: 'שם פרטי', initialValue: '', icon: 'user',
                },
                {
                  type: 'text', name: 'last_name', placeholder: 'שם משפחה', initialValue: '', icon: 'user',
                },
                {
                  type: 'number', name: 'phone_number', placeholder: 'מספר פלאפון', initialValue: '', icon: 'phone',
                },
                {
                  type: 'text', name: 'role', initialValue: this.state.role, icon: 'user', readOnly: true,
                },
              ]}
              submitText="עדכן פרטים"
              validationFunc="profileUpdate"
              onSuccess={this._success}
            />
            <Message>
              <ChangePassword />
            </Message>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

export default ProfileUpdateForm;
