import React from 'react';
import {
  Form, Segment, Button, Message,
} from 'semantic-ui-react';
import api from '../api';

class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      loading: false,
      loadingAddition: false,
      additionError: false,
    };
    this._submit = this._submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._errorHandler = this._errorHandler.bind(this);
    this._success = this._success.bind(this);
    this._buildInput = this._buildInput.bind(this);
    this._init = this._init.bind(this);
    this._getFieldPropsValues = this._getFieldPropsValues.bind(this);
    this._onSelectAddedItem = this._onSelectAddedItem.bind(this);
  }

  componentWillMount() {
    this._init();
  }

  _getFieldPropsValues() {
    const { field_options } = this.props;
    const arr = [];
    for (let i = 0; i < field_options.length; i++) {
      if (field_options[i].readOnly)
        continue;
      arr.push(this.state[field_options[i].name]);
    }

    return arr;
  }

  _init() {
    const { field_options } = this.props;
    const state_obj = { error: {}, loading: false };
    for (let i = 0; i < field_options.length; i++) {
      if (field_options[i].readOnly)
        continue;
      if (field_options[i].type === 'select' && (field_options[i].allowAdditions || field_options[i].isRational))
        state_obj[`${field_options[i].name}_options`] = field_options[i].options;
      state_obj[field_options[i].name] = field_options[i].initialValue;
    }

    this.setState(state_obj);
  }

  async _onSelectAddedItem(value, func, stateKey) {
    await this.setState({ loadingAddition: true });
    const { data } = await api[func](value);
    if (data.error)
      return this.setState({ loadingAddition: false, additionError: true });

    const newOption = { key: data.name, text: data.name, value: data.name };
    const newArray = [{ ...newOption }, ...this.state[stateKey]];
    this.setState({ [stateKey]: newArray, loadingAddition: false });
  }

  _buildInput(field) {
    return field.type === 'select'
      ? (
        <Form.Select
          key={field.name}
          error={field.allowAdditions ? this.state.additionError : this.state.error.type === field.name ? this.state.error.obj : false}
          value={field.readOnly ? field.initialValue : this.state[field.name]}
          fluid
          placeholder={field.placeholder}
          options={(field.allowAdditions || field.isRational) ? this.state[`${field.name}_options`] : field.options}
          allowAdditions={!!field.allowAdditions}
          search={!!field.allowAdditions}
          onAddItem={field.allowAdditions ? (e, { value }) => this._onSelectAddedItem(value, field.item_addition_api_func, `${field.name}_options`) : null}
          onChange={(e, data) => this.handleChange(field.name, data.value, field.onChangeEndFunc, `${field.state_property_relation}_options`)}
          loading={this.state.loadingAddition}
        />
      )
      : (
        <Form.Input
          placeholder={field.placeholder}
          value={field.readOnly ? field.initialValue : this.state[field.name]}
          type={field.type}
          onChange={e => this.handleChange(field.name, e.target.value)}
          error={this.state.error.type === field.name ? this.state.error.obj : false}
          fluid
          icon={field.icon ? field.icon : null}
          readOnly={field.readOnly ? field.readOnly : false}
          key={field.name}
          className={field.type === 'hidden' ? 'hide-input' : ''}
        />
      );
  }

  async handleChange(type, value, onChangeEndFunc = null, state_property_relation) {
    await this.setState({ [type]: value });
    if (onChangeEndFunc && {}.toString.call(onChangeEndFunc) === '[object Function]') {
      const options = await onChangeEndFunc(value);
      this.setState({ [state_property_relation]: options });
    }
  }

  _errorHandler(error) {
    this.setState({ loading: false, error });
  }

  async _submit(e) {
    e.preventDefault();
    this.setState({ loading: true, error: {} });
    const data = await api[this.props.apiCall](...this._getFieldPropsValues());

    if (data.error)
      return this._errorHandler(data);

    this._success(data);
  }

  _success(res) {
    if (this.props.shouldResetOnSuccess)
      this._init();
    this.props.onSuccess(res);
  }

  render() {
    return (
      <Form size="large" onSubmit={this._submit} loading={this.state.loading} style={this.props.style ? this.props.style : null}>
        <Segment stacked textAlign="right">
          {this.props.field_options.map(field => this._buildInput(field))}
          {this.state.error.type === 'general' ? (
            <Message negative>
              {this.state.error.obj.content}
            </Message>
          ) : null}
          {this.props.isModalForm ? (
            <div className="align-content-to-center">
              <Button size="large" type="button" onClick={this.props.close}>
                {this.props.submitText[0]}
              </Button>
              <Button color="teal" size="large">
                {this.props.submitText[1]}
              </Button>
            </div>
          ) : (
            React.isValidElement(this.props.submitText) ? (
              this.props.submitText
            ) : (
              <Button color="teal" fluid size="large" style={{ marginRight: 0 }}>
                {this.props.submitText}
              </Button>
            )
          )}

        </Segment>
        {this.props.children}
      </Form>
    );
  }
}

export default CustomForm;
