import React from 'react';
import { Header, Modal } from 'semantic-ui-react';
import strings from '../strings';

export const About = props => (
  <Modal open={props.open} onClose={props.close}>
    <Modal.Header><Header textAlign="right">מידע כללי</Header></Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <div className="rtl-container">
          <p>
מספר גרסה:
            {strings.VERSION_NUMBER}
          </p>
        </div>
      </Modal.Description>
    </Modal.Content>
  </Modal>
);
