import React from 'react';
import {
  Button, Header, Modal, Grid,
} from 'semantic-ui-react';
import CustomForm from './CustomForm';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_password: '',
      new_password: '',
      reapet_password: '',
    };

    this._updatePassword = this._updatePassword.bind(this);
    this._success = this._success.bind(this);
  }

  async _updatePassword() {
    // e.preventDefault();
    // const { current_password, new_password, reapet_password } = this.state;
    // await this.setState({ loading: true, error: {} });
    // const error = validation.changePassword(current_password, new_password, reapet_password);
    //
    // console.log(error);
    //
    // if (error.error) return this._errorHandler(error);
    //
    // setTimeout(() => this._success(), 500);
  }

  _success() {

  }

  render() {
    return (
      <Modal trigger={<Button secondary type="button">שנה סיסמא</Button>}>
        <Header textAlign="right">שנה סיסמא</Header>
        <Grid textAlign="center">
          <Grid.Column className="form-container-width">
            {/* }<Form style={{padding: 20}}>
          <Segment>
            <Form.Input fluid placeholder="הזן/י את הסיסמא הנוכחית" value={this.state.current_password} onChange={(e) => this.handleChange('current_password', e.target.value)} type="password"/>

            <Form.Input fluid placeholder="סיסמא חדשה" value={this.state.new_password} onChange={(e) => this.handleChange('new_password', e.target.value)} type="password"/>

            <Form.Input fluid placeholder="חזור שנית על הסיסמא החדשה" value={this.state.reapet_password} onChange={(e) => this.handleChange('reapet_password', e.target.value)} type="password"/>

            <Button color='teal' fluid size='large'>
              שנה סיסמא
            </Button>
          </Segment>
        </Form> */}
            <CustomForm
              field_options={[
                {
                  type: 'password', name: 'current_password', placeholder: 'הזן את הסיסמא הנוכחית', initialValue: '',
                },
                {
                  type: 'password', name: 'new_password', placeholder: 'סיסמא חדשה', initialValue: '',
                },
                {
                  type: 'password', name: 'reapet_password', placeholder: 'חזור שנית על הסיסמא החדשה', initialValue: '',
                },
              ]}
              submitText="שנה סיסמא"
              validationFunc="changePassword"
              style={{ padding: 20 }}
            />
          </Grid.Column>
        </Grid>
      </Modal>
    );
  }
}

export default ChangePassword;
