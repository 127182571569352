import strings from '../strings';
import buildError from './buildError';

export default {
  login: code => {
    let translated;
    let type;
    switch (code) {
      case 'NotFound':
        translated = strings.USER_NOT_FOUND;
        type = 'email';
        break;
      case 'BadRequest':
        translated = strings.BAD_LOGIN_REQUEST;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  createUser: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.EMAIL_ALREADY_EXISTS;
        type = 'email';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  createEvent: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.EVENT_NAME_EXISTS;
        type = 'name';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  createStaff: code => {
    let translated;
    let type;
    switch (code) {
      case 'Staff already exists':
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  staffToEvent: code => {
    let translated;
    let type;
    switch (code) {
      case 'Staff already exists':
        translated = 'exists';
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  createCandidate: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.CANDIDATE_EXISTS;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  candidateToTeam: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.NUMBER_IN_TEAM_EXISTS;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  addTeam: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.TEAM_EXISTS;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  changeCandidateStatus: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },

  relateUserToTeam: code => {
    let translated;
    let type;
    switch (code) {
      case 'Conflict':
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
        break;
      default:
        translated = strings.GENERAL_ERROR_MESSAGE;
        type = 'general';
    }

    return buildError(type, translated, 'below');
  },
};
