import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import api from '../api';

const RedirectToDashboard = ({ component: Component, ...rest }) => {
  const [ready, setReady] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  async function fetchIsLoggedIn() {
    const isLoggedIn = await api.isLoggedIn();
    setLoggedIn(isLoggedIn);
    setReady(true);
  }

  useEffect(() => {
    fetchIsLoggedIn();
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) => (
        ready ? (
          loggedIn ? (
            <Redirect
              to={{
                pathname: '/dashboard',
              }}
            />
          ) : (
            <Component />
          )
        ) : (
          <Loader />
        )
      )}
    />
  );
};

export default RedirectToDashboard;
