import React, { useEffect, useState } from 'react';
import {
  Modal, Icon, Button,
} from 'semantic-ui-react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import api from '../../api';
import CandidateRow from './CandidateRow';

const MultipleCandidatesModal = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [staff, setStaff] = useState([]);
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);

  async function fetchStaff() {
    try {
      const { data } = await api.getStaffByEvent(props.event_name);
      setStaff(data);
    } catch ({ response }) {
      if (response && response.data && response.data.message)
        setError(response.data.message);
    }
  }

  useEffect(() => {
    fetchStaff();
  }, [isOpen]);

  function getUploadParams({ meta }) { return { url: 'https://httpbin.org/post' }; }

  // called every time a file's `status` changes
  function handleChangeStatus({ meta, file }, status) { console.log(status, meta, file); }

  // receives array of files that are done uploading when submit button is clicked
  async function handleSubmit(files) {
    try {
      setIsPending(true);
      setError('');
      const result = await api.sendImage(files);
      setCandidates(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsPending(false);
    }
  }

  function renderCandidates() {
    return candidates.length ? (
      <table className="ui table">
        <thead>
          <tr>
            <th>Staff</th>
            <th>Number in team</th>
            <th>Candidate ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {candidates.map(candidate => (
            <CandidateRow
              staff={staff}
              key={candidate.candidateId}
              candidateId={candidate.candidateId}
              shirtNumber={candidate.shirtNumber}
              eventName={props.event_name}
              teamId={props.team_id}
            />
          ))}
        </tbody>
      </table>
    ) : null;
  }


  return (
    <Modal
      size="small"
      className="rtl-container"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={(
        <Button icon labelPosition="right" size="tiny" primary onClick={() => setIsOpen(true)}>
            הוסף מתגבשים
          <Icon name="user plus" />
        </Button>
        )}
    >
      <Modal.Content className="rtl-container">
        <Dropzone
          maxFiles={1}
          submitButtonDisabled={isPending}
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          accept="image/*"
        />
        {
          error
            ? (
              <div className="ui warning message">
                <p>{error}</p>
              </div>
            )
            : renderCandidates()
        }
      </Modal.Content>
    </Modal>
  );
};

export default MultipleCandidatesModal;
